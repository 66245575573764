<template>
  <section
    :style="styledBg"
    :class="{ dark: section.dark_mode }"
    class="
      component
      position-relative
      c-series
      padding-y-24
      bp-768:padding-y-48
    "
    :aria-label="section.headline"
  >
    <div class="row padding-y-12 position-relative z-index-3">
      <header class="display-flex width-100 padding-x-12">
        <div
          class="
            c-series__header--info
            align-items-center
            display-flex
            flex-item-1
          "
        >
          <div v-if="section.logo" class="c-series__header--logo margin-r-24">
            <img :src="section.logo + '?width=100'" :alt="section.title" />
          </div>

          <div
            class="
              c-series__header--content
              padding-x-24
              flex-item-1
              display-flex
              flex-direction-column
              justify-content-center
            "
          >
            <div
              v-if="section.subHeadline"
              class="
                c-series__header--logo
                margin-b-8
                font-weight-700 font-size-16
                bp-768:font-size-18
                line-height-125
              "
            >
              {{ section.subHeadline }}
            </div>
            <h2
              class="
                margin-0
                font-size-21
                bp-768:font-size-48
                line-height-125
                font-weight-700
              "
              v-if="section.headline"
            >
              {{ section.headline }}
            </h2>
          </div>
        </div>
        <div
          v-if="
            section.showCountdown &&
            section.seriesGames &&
            section.seriesGames.length > 0
          "
          class="c-series__countdown"
        >
          <vue-countdown
            class="c-series__countdown__wrapper"
            :time="time"
            v-slot="{ days, hours, minutes, seconds }"
          >
            <div
              class="c-series__countdown--time margin-x-12 bp-1280:margin-x-24"
            >
              <div>{{ days }}</div>
              <div>Days</div>
            </div>

            <div
              class="c-series__countdown--time margin-x-12 bp-1280:margin-x-24"
            >
              <div>{{ hours }}</div>
              <div>Hours</div>
            </div>
            <div
              class="c-series__countdown--time margin-x-12 bp-1280:margin-x-24"
            >
              <div>{{ minutes }}</div>
              <div>Minutes</div>
            </div>
            <div
              class="c-series__countdown--time margin-x-12 bp-1280:margin-x-24"
            >
              <div>{{ seconds }}</div>
              <div>Seconds</div>
            </div>
          </vue-countdown>
        </div>
      </header>
    </div>
    <div class="row padding-y-12 position-relative z-index-3">
      <swiper
        class="
          c-series__list
          flex-item-1
          width-100
          padding-6
          bp-768:display-flex
        "
        :options="swiperOption"
        ref="swiperTop"
      >
        <swiper-slide
          class="display-flex"
          v-for="event in section.seriesGames"
          :key="event.game.id"
        >
          <div
            class="
              c-series__desktop c-series__item
              display-flex
              flex-direction-column
            "
          >
            <div class="c-series__item--inner">
              <header
                class="
                  c-series__item--header
                  display-flex
                  align-items-center
                  justify-content-flex-end
                  flex-item-1
                "
              >
                <div
                  v-if="
                    event.game &&
                    event.game.coverage &&
                    event.game.coverage.tickets
                  "
                  class="c-series__item--tickets font-size-21 margin-r-auto"
                >
                  <a :href="event.game.coverage.tickets">
                    <i class="sf-tickets"></i>
                  </a>
                </div>
                <div
                  v-if="
                    event.game &&
                    event.game.opponent &&
                    event.game.opponent.logo
                  "
                  class="c-series__item--logo"
                >
                  <img
                    :src="event.game.opponent.logo + '?width=74'"
                    :alt="event.game.opponent.title + ' ' + event.game.id"
                  />
                </div>
                <div
                  class="
                    c-series__item--title
                    font-size-16 font-weight-700
                    line-height-150
                  "
                >
                  {{ event.label }}
                </div>
              </header>
              <div class="c-series__item--content padding-b-12">
                <div
                  v-if="
                    event.game &&
                    event.game.opponent &&
                    event.game.opponent.title
                  "
                  class="
                    c-series__item--opponent
                    font-size-16 font-weight-700
                    line-height-150
                    margin-b-8
                  "
                >
                  <span v-if="event.game.indicator === 'A'">at</span>
                  <span v-if="event.game.indicator === 'H'">vs.</span>
                  <span v-if="event.game.indicator === 'N'">at</span>
                  <span> {{ event.game.opponent.title }}</span>
                </div>
                <div
                  class="
                    c-series__item--datetime
                    font-size-16 font-weight-400
                    line-height-150
                    margin-b-8
                    flex-item-1
                    display-flex
                    justify-content-flex-start
                  "
                >
                  <span>
                    {{
                      event.game.date
                        ? moment(event.game.date).format("dddd, MMMM DD")
                        : "N/A"
                    }}</span
                  >
                  <span class="font-weight-700 margin-l-auto">
                    {{ event.game.time }}</span
                  >
                </div>
                <div
                  v-if="event.game && event.game.location"
                  class="c-series__item--location font-size-14 line-height-150"
                >
                  {{ event.game.location }}
                </div>
              </div>
              <div class="c-series__item--links">
                <ul class="reset-list">
                  <template v-if="event.game.result">
                    <li
                      v-if="
                        event.game.result.result_opponent_score &&
                        event.game.result.result_team_score &&
                        event.game.result.result_status
                      "
                    >
                      <a
                        :style="{
                          cursor: 'default',
                          textDecoration: 'none',
                        }"
                      >
                        {{
                          `${event.game.result.result_status}, ${
                            event.game.result.result_team_score
                          }-${event.game.result.result_opponent_score}${
                            event.game.result.result_postscore_info
                              ? " " + event.game.result.result_postscore_info
                              : ""
                          }`
                        }}
                      </a>
                    </li>

                    <template
                      v-if="
                        event.game.postgame_story &&
                        event.game.postgame_story.link
                      "
                    >
                      <li>
                        <a :href="event.game.postgame_story.link"> Recap </a>
                      </li>
                    </template>

                    <template
                      v-if="event.game.result && event.game.result.boxscore_url"
                    >
                      <li>
                        <a :href="event.game.result.boxscore_url">
                          Box Score
                        </a>
                      </li>
                    </template>
                  </template>
                  <template v-else>
                    <template v-for="link in event.coverageElements">
                      <template v-if="!link.is_custom">
                        <li
                          :key="link.id"
                          v-if="
                            link.id == 'tv' &&
                            event &&
                            event.game &&
                            event.game.coverage.tv &&
                            link.show
                          "
                        >
                          <span
                            v-if="event && event.game && event.game.coverage.tv"
                            class="c-coverage__primary"
                          >
                            {{ event.game.coverage.tv }}
                          </span>
                        </li>
                        <li
                          :key="link.id"
                          v-if="
                            link.id == 'radio' &&
                            event &&
                            event.game &&
                            event.game.coverage.radio &&
                            link.show
                          "
                        >
                          <span
                            v-if="
                              event && event.game && event.game.coverage.radio
                            "
                            class="c-coverage__primary"
                          >
                            {{ event.game.coverage.radio }}
                          </span>
                        </li>
                        <li
                          :key="link.id"
                          v-if="
                            link.id == 'streaming' &&
                            event &&
                            event.game &&
                            event.game.coverage.streaming
                          "
                        >
                          <a
                            :href="event.game.coverage.streaming"
                            v-if="
                              event &&
                              event.game &&
                              event.game.coverage.streaming
                            "
                            class="c-coverage__primary"
                          >
                            Watch Live
                          </a>
                        </li>
                        <li
                          :key="link.id"
                          v-if="
                            link.id == 'audio' &&
                            event &&
                            event.game &&
                            event.game.coverage.audio &&
                            link.show
                          "
                        >
                          <a
                            :href="event.game.coverage.audio"
                            v-if="
                              event && event.game && event.game.coverage.audio
                            "
                            class="c-coverage__primary"
                          >
                            Listen Live
                          </a>
                        </li>
                        <li
                          :key="link.id"
                          v-if="
                            link.id == 'stats' &&
                            event &&
                            event.game &&
                            event.game.coverage.stats &&
                            link.show
                          "
                        >
                          <a
                            :href="event.game.coverage.stats"
                            v-if="
                              event && event.game && event.game.coverage.stats
                            "
                            class="c-coverage__primary"
                          >
                            Live Stats
                          </a>
                        </li>
                        <li
                          :key="link.id"
                          v-if="
                            link.id == 'tickets' &&
                            event &&
                            event.game &&
                            event.game.coverage.tickets &&
                            link.show
                          "
                        >
                          <a
                            :href="event.game.coverage.tickets"
                            v-if="
                              event && event.game && event.game.coverage.tickets
                            "
                            class="c-coverage__primary"
                          >
                            Tickets
                          </a>
                        </li>
                        <li
                          :key="link.id"
                          v-if="
                            link.id == 'pregame_story' &&
                            event &&
                            event.game &&
                            event.game.pregame_story &&
                            event.game.pregame_story.id &&
                            link.show
                          "
                        >
                          <a
                            :href="event.game.pregame_story.link"
                            v-if="
                              event &&
                              event.game &&
                              event.game.pregame_story &&
                              event.game.pregame_story.id
                            "
                            class="c-coverage__primary"
                          >
                            Story Preview
                          </a>
                        </li>
                        <li
                          :key="link.id"
                          v-if="
                            link.id == 'teamStoreURL' &&
                            event &&
                            event.coverage &&
                            event.coverage.teamStoreURL &&
                            link.show
                          "
                        >
                          <a
                            :href="event.coverage.teamStoreURL"
                            v-if="
                              event &&
                              event.coverage &&
                              event.coverage.teamStoreURL
                            "
                            class="c-coverage__primary"
                          >
                            Shop Online
                          </a>
                        </li>
                      </template>
                    </template>

                    <template v-for="link in event.coverageElements">
                      <li v-if="link.show && link.link_url" :key="link.id">
                        <a v-if="link.link_url" :href="link.link_url">{{
                          link.link_text
                        }}</a>
                      </li>
                    </template>
                  </template>
                </ul>
              </div>
              <div class="c-series__item--teams font-size-14 line-height-150">
                <div
                  v-if="event && event.game && event.game.awayTeam"
                  class="c-series__item--team c-series__item--team-away"
                >
                  <span> {{ event.game.awayTeam.title }}:</span>
                  <ul class="reset-list">
                    <template
                      v-for="(link, index) in event.game.awayTeam.links"
                    >
                      <li v-if="link.url" :key="index">
                        <a :href="link.url">{{ link.title }}</a>
                      </li>
                    </template>
                  </ul>
                </div>
                <div
                  v-if="event && event.game && event.game.homeTeam"
                  class="c-series__item--team c-series__item--team-away"
                >
                  <span> {{ event.game.homeTeam.title }}:</span>
                  <ul class="reset-list">
                    <template
                      v-for="(link, index) in event.game.homeTeam.links"
                    >
                      <li v-if="link.url" :key="index">
                        <a :href="link.url">{{ link.title }}</a>
                      </li>
                    </template>
                  </ul>
                </div>
              </div>
            </div>
            <div
              v-if="
                event &&
                event.playerSpotlight &&
                event.playerSpotlight.players.length > 0
              "
              class="c-series__item--spotlight"
            >
              <div
                class="font-size-14 line-height-150 font-weight-700"
                v-if="event.playerSpotlight.heading"
              >
                {{ event.playerSpotlight.heading }}
              </div>
              <ul class="reset-list">
                <li
                  class="c-series__player margin-t-12 display-flex"
                  v-for="player in event.playerSpotlight.players"
                  :key="player.player_id"
                >
                  <div v-if="player.image" class="c-series__player--image">
                    <img
                      :src="player.image + '?width=54'"
                      :alt="
                        player.first_name +
                        ' ' +
                        player.last_name +
                        ' ' +
                        player.player_id
                      "
                    />
                  </div>
                  <div class="c-series__player--content flex-item-1 padding-24">
                    <div
                      v-html="`${player.first_name} ${player.last_name}`"
                      v-if="player.firstname != '' || player.last_name != ''"
                      class="
                        c-series__player--name
                        font-size-14 font-weight-400
                        line-height-150
                      "
                    ></div>
                    <div
                      v-if="player.position"
                      class="c-series__player--position"
                    >
                      {{ player.position }}
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
    <div
      class="
        position-relative
        z-index-3
        width-100
        padding-t-12
        swiper-arrows
        display-flex
        justify-content-center
      "
    >
      <div
        class="
          swiper-button-prev3
          margin-x-12
          swiper-button-white
          c-common__arrow-alt c-common__arrow
          display-flex
        "
        slot="button-prev"
      >
        <feather size="24px" type="arrow-left"></feather>
      </div>
      <a
        class="
          s-common__button s-common__button-large
          c-schedule__more
          text-align-center
          margin-0
          font-size-14
          line-height-125
          font-weight-700
          display-flex
        "
        :href="'/sports/' + this.$root.gameday.sport.shortname + '/schedule/'"
      >
        <feather class="margin-r-6" size="16px" type="calendar"></feather>
        Full Schedule</a
      >
      <div
        class="
          swiper-button-next3
          margin-x-12
          swiper-button-white
          display-flex
          c-common__arrow-alt c-common__arrow
        "
        slot="button-next"
      >
        <feather size="24px" type="arrow-right"></feather>
      </div>
    </div>
  </section>
</template>

<script>
import moment from "moment";
import SectionBase from "./SectionBase";
import VueCountdown from "@chenfengyuan/vue-countdown";
export default {
  extends: SectionBase,
  name: "SectionSeries",
  components: {
    VueCountdown,
  },
  data: () => ({
    swiperOption: {
      slidesPerView: 1,
      navigation: {
        nextEl: ".swiper-button-next3",
        prevEl: ".swiper-button-prev3",
      },
      breakpoints: {
        1230: {
          slidesPerView: 3,
        },
        768: {
          slidesPerView: 2,
        },
        0: {
          slidesPerView: 1,
        },
      },
    },
  }),
  methods: {},
  computed: {
    styledBg: function () {
      if (this.section.backgroundImage) {
        return {
          "background-image": "url(" + this.section.backgroundImage + ") ",
        };
      } else if (this.section.useCustomBackgroundColor) {
        return { backgroundColor: this.section.custom_background_color };
      }
      return {};
    },
    time: function () {
      return moment(this.section.seriesGames[0].game.date) - moment();
    },
  },
  mounted() {},
};
</script>

<style scoped lang="scss">
.c-series {
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    background: $main-color;
    opacity: 0.7;
    z-index: 2;
  }
  .swiper-slide {
    padding: 0 6px;
  }
  &__header {
    &--logo {
    }
  }
  &__countdown {
    flex-shrink: 0;
    margin-left: auto;
    &__wrapper {
      display: flex;
    }
    &--time {
      &:last-child {
        margin-right: 0;
      }
      text-align: center;
      > div:first-child {
        width: 96px;
        height: 96px;
        border-radius: 50%;
        color: $main-font-color;
        border: 2px dashed $main-font-color;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        font-size: 42px;
        line-height: 125%;
      }
      div:last-child {
        margin-top: 8px;
        font-size: 1rem;
        line-height: 150%;
        font-weight: 700;
        text-align: center;
      }
    }
  }
  &__player {
    &--image {
      width: 60px;
      border: 3px solid #f1f1f1;
    }
    &--position {
      color: $color-safe-text;
    }
  }
  &__header {
    &--content {
      border-left: 1px dashed $main-font-color;
    }
  }
  &__item {
    margin-top: 26px;
    border-radius: 10px;
    flex: 1 1 100%;
    border: 1px solid $main-border-color;
    min-height: 498px;
    background: #ffffff;
    color: #000;
    a {
      color: #000;
    }
    &--title {
      color: #666;
    }
    &--tickets {
      a {
        color: #000;
        font-size: 21px;
      }
    }
    &--header {
      min-height: 24px;
      margin-bottom: 50px;
    }
    &--location {
      color: #666;
    }
    &--teams {
    }
    &--content {
      border-bottom: 1px solid $main-border-color;
      margin-bottom: 12px;
    }
    &--logo {
      border: 1px solid $main-border-color;
      width: 100px;
      overflow: hidden;
      height: 100px;
      background: #fff;
      position: absolute;
      border-radius: 50%;
      left: 50%;
      margin-left: -50px;
      top: -26px;
      padding: 12px;
    }
    &--inner {
      padding: 24px 24px 12px 24px;
      position: relative;
    }
    &--spotlight {
      padding: 24px;
      border-top: 1px solid #f1f1f1;
    }
    &--teams {
      margin-top: 24px;
      color: #666;
      span:first-child {
        margin-right: 6px;
      }
      ul {
        display: inline;
        li {
          display: inline;
          a {
            color: #666;
            display: inline-block;
            text-decoration: none;
            &:hover {
              text-decoration: underline;
            }
          }
          &:before {
            content: "|";
            margin: 0 3px;
          }
          &:first-child {
            &:before {
              content: none;
            }
          }
        }
      }
    }
    &--links {
      ul {
        display: inline;
        li {
          font-weight: 700;
          font-size: 1rem;
          line-height: 150%;
          // color: $color-safe-text;
          display: inline;
          a {
            display: inline-block;
            text-decoration: none;
            &:hover {
              text-decoration: underline;
            }
          }
          &:before {
            content: "|";
            margin: 0 6px;
          }
          &:first-child {
            &:before {
              content: none;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 1279px) {
    &__countdown {
      display: none;
    }
  }
}
</style>
<style lang="scss">
.dark {
  .c-series__item--logo {
    border: none;
  }
}
</style>
